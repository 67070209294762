import React, { useState, useEffect } from "react";
import "./PopupsGameStyle.css";
import CloseButton from "../../CloseButton/CloseButton";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { allStorageClear } from "../../../pages/Game/store";
import { checkGamePrizes } from "../../../pages/Game/store";
import Loading from "../../Loading/Loading";
import { utils } from "ethers";
//images
import facebookImg from "../../../assets/img/facebook.png";
import twitterImg from "../../../assets/img/twitter.png";
import invite2Img from "../../../assets/img/invite2.png";
import popupBgImg from "../../../assets/img/popup-bg.png";
import logo from "../../../assets/img/logo.svg";
import avatar from "../../../assets/img/avatars/avatar6.png";
import { hexToArry } from "../../CreateCards";

const WinnerLoserPopup = ({
  gameId,
  game,
  contractAndAuth,
  wallet,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const [prizesWon, setPrizesWon] = useState([]);
  const [prizeWonAmounts, setprizeWonAmounts] = useState([]);
  const [jammy, setJammy] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const winnersImages = require.context(
    "../../../assets/img/winpopupheaders",
    true
  );

  const winnersImageList = winnersImages
    .keys()
    .map((image) => winnersImages(image));

  const losersImages = require.context(
    "../../../assets/img/losepopupheaders",
    true
  );

  const losersImageList = losersImages
    .keys()
    .map((image) => losersImages(image));

  const generate_random_number = (max) => Math.floor(Math.random() * max);

  // const prizesSummary = async () => {
  //   try {
  //     // uint256 totalPot = game.cardPrice * game.totalCardsSold; = totalAmount
  //     // const totalPot = Number(
  //     //   (+utils.formatEther(BigNumber.from(game[2] * game[4]))).toFixed(0)
  //     // );
  //     const totalPot = Number(game[2] * game[4]);
  //     console.log("Total Pot (totalAmount):", totalPot); // %100
  //     const pendingFunds = Number(
  //       (+utils.formatEther(
  //         BigNumber.from(await contractAndAuth.contract.pendingFunds())
  //       )).toFixed(2)
  //     );
  //     // const games = await contractAndAuth.contract.games(gameId);
  //     // const gamecardprice = Number((+utils.formatEther(BigNumber.from(games[3]))).toFixed(0));
  //     // const totalCardsSold = Number(games[4]);

  //     const houseShare = Number(game[6]); // totalPot un %10
  //     const team1Shares = Number(await contractAndAuth.contract.team1Shares());
  //     const team2Shares = Number(await contractAndAuth.contract.team2Shares());
  //     const team1Address = await contractAndAuth.contract.team1Address();
  //     const team2Address = await contractAndAuth.contract.team2Address();
  //     const jam1Share = (
  //       await contractAndAuth.contract.gamePrizes(gameId, 4)
  //     )[0];
  //     const jam2Share = (
  //       await contractAndAuth.contract.gamePrizes(gameId, 3)
  //     )[0];
  //     const jam3Share = (
  //       await contractAndAuth.contract.gamePrizes(gameId, 2)
  //     )[0];
  //     const jam4Share = (
  //       await contractAndAuth.contract.gamePrizes(gameId, 1)
  //     )[0];
  //     const jammyShare = (
  //       await contractAndAuth.contract.gamePrizes(gameId, 0)
  //     )[0];

  //     const houseAmount =
  //       (Number(totalPot) * houseShare) / contractAndAuth.precisionBasis;
  //     const team1Amount =
  //       (houseAmount * team1Shares) / contractAndAuth.precisionBasis;
  //     const team2Amount =
  //       (houseAmount * team2Shares) / contractAndAuth.precisionBasis;
  //     const jam1PrizeAmount =
  //       (Number(totalPot) * jam1Share) / contractAndAuth.precisionBasis;
  //     const jam2PrizeAmount =
  //       (Number(totalPot) * jam2Share) / contractAndAuth.precisionBasis;
  //     const jam3PrizeAmount =
  //       (Number(totalPot) * jam3Share) / contractAndAuth.precisionBasis;
  //     const jam4PrizeAmount =
  //       (Number(totalPot) * jam4Share) / contractAndAuth.precisionBasis;
  //     const jammyPrizeAmount =
  //       (Number(totalPot) * jammyShare) / contractAndAuth.precisionBasis;

  //     console.log(`houseShare (%${houseShare / 100}):`, houseAmount);
  //     console.log(
  //       `team1Amount (%${team1Shares / 1000}):`,
  //       team1Amount,
  //       `-> (${team1Address.slice(0, 6)}...${team1Address.slice(
  //         team1Address.length - 4,
  //         team1Address.length
  //       )})`
  //     );
  //     console.log(
  //       `team2Amount (%${team2Shares / 1000}):`,
  //       team2Amount,
  //       `-> (${team2Address.slice(0, 6)}...${team2Address.slice(
  //         team2Address.length - 4,
  //         team2Address.length
  //       )})`
  //     );
  //     console.log(`jam1 PrizeAmount (%${jam1Share / 100}):`, jam1PrizeAmount);
  //     console.log(`jam2 PrizeAmount (%${jam2Share / 100}):`, jam2PrizeAmount);
  //     console.log(`jam3 PrizeAmount (%${jam3Share / 100}):`, jam3PrizeAmount);
  //     console.log(`jam4 PrizeAmount (%${jam4Share / 100}):`, jam4PrizeAmount);
  //     console.log(
  //       `jammy PrizeAmount (%${jammyShare / 100}):`,
  //       jammyPrizeAmount
  //     );

  //     let jam1Winners = [];
  //     let jam2Winners = [];
  //     let jam3Winners = [];
  //     let jam4Winners = [];
  //     let jammyWinners = [];
  //     for (let prizeIndex = 4; prizeIndex >= 0; prizeIndex--) {
  //       try {
  //         for (let winnerIndex = 0; ; winnerIndex++) {
  //           switch (prizeIndex) {
  //             case 4:
  //               jam1Winners.push(
  //                 await contractAndAuth.contract.prizeWinners(
  //                   gameId,
  //                   prizeIndex,
  //                   winnerIndex
  //                 )
  //               );
  //               break;

  //             case 3:
  //               jam2Winners.push(
  //                 await contractAndAuth.contract.prizeWinners(
  //                   gameId,
  //                   prizeIndex,
  //                   winnerIndex
  //                 )
  //               );
  //               break;

  //             case 2:
  //               jam3Winners.push(
  //                 await contractAndAuth.contract.prizeWinners(
  //                   gameId,
  //                   prizeIndex,
  //                   winnerIndex
  //                 )
  //               );
  //               break;

  //             case 1:
  //               jam4Winners.push(
  //                 await contractAndAuth.contract.prizeWinners(
  //                   gameId,
  //                   prizeIndex,
  //                   winnerIndex
  //                 )
  //               );
  //               break;

  //             case 0:
  //               jammyWinners.push(
  //                 await contractAndAuth.contract.prizeWinners(
  //                   gameId,
  //                   prizeIndex,
  //                   winnerIndex
  //                 )
  //               );
  //               break;
  //           }
  //         }
  //       } catch (error) {
  //         // console.log(error);
  //       }
  //     }

  //     //TODO (akkoc): burda oyuncuya ait kişisel oyun sonu istatistikleri var.
  //     //genel olarak bu oyunda hangi jamlerden hangi oyuncuların ne kadar matic kazandıkları var.
  //     //burası genel oyun sonu o oyuna ait tam bir dağılım istatistiğidir.
  //     jam1Winners.forEach((winner) => {
  //       console.log(
  //         `Jam1 Winner (${winner.slice(0, 6)}...${winner.slice(
  //           winner.length - 4,
  //           winner.length
  //         )}):`,
  //         jam1PrizeAmount / jam1Winners.length,
  //         `(${jam1PrizeAmount}/${jam1Winners.length})`
  //       );
  //     });
  //     jam2Winners.forEach((winner) => {
  //       console.log(
  //         `Jam2 Winner (${winner.slice(0, 6)}...${winner.slice(
  //           winner.length - 4,
  //           winner.length
  //         )}):`,
  //         jam2PrizeAmount / jam2Winners.length,
  //         `(${jam2PrizeAmount}/${jam2Winners.length})`
  //       );
  //     });
  //     jam3Winners.forEach((winner) => {
  //       console.log(
  //         `Jam3 Winner (${winner.slice(0, 6)}...${winner.slice(
  //           winner.length - 4,
  //           winner.length
  //         )}):`,
  //         jam3PrizeAmount / jam3Winners.length,
  //         `(${jam3PrizeAmount}/${jam3Winners.length})`
  //       );
  //     });
  //     jam4Winners.forEach((winner) => {
  //       console.log(
  //         `Jam4 Winner (${winner.slice(0, 6)}...${winner.slice(
  //           winner.length - 4,
  //           winner.length
  //         )}):`,
  //         jam4PrizeAmount / jam4Winners.length,
  //         `(${jam4PrizeAmount}/${jam4Winners.length})`
  //       );
  //     });
  //     jammyWinners.forEach((winner) => {
  //       console.log(
  //         `Jammy Winner (${winner.slice(0, 6)}...${winner.slice(
  //           winner.length - 4,
  //           winner.length
  //         )}):`,
  //         jammyPrizeAmount / jammyWinners.length,
  //         `(${jammyPrizeAmount}/${jammyWinners.length})`
  //       );
  //     });

  //     console.log("pendingFunds:", pendingFunds);

  //     if (contractAndAuth.contractBalance) {
  //       console.log(
  //         "Contract Balance:",
  //         Number(
  //           (+utils.formatEther(
  //             BigNumber.from(contractAndAuth.contractBalance)
  //           )).toFixed(2)
  //         )
  //       );
  //     }

  //     //TODO (akkoc): burda oyuncuya ait kişisel oyun sonu istatistikleri var.
  //     //gameId oyunda, wallet.accounts[0] oyuncu hangi jamleri (jam1Winners gibi) yaptıysa o jamlerden payına düşen matic miktarları (jam1PrizeAmount / jam1Winners.length) var
  //     console.log(`#-> Your end game statistics (${wallet.accounts[0]}) <-#`);
  //     jam1Winners.forEach((winner) => {
  //       if (winner.toLowerCase() === wallet.accounts[0].toLowerCase()) {
  //         setPlayerJam1Amount(jam1PrizeAmount / jam1Winners.length);
  //         console.log(
  //           `> You won Jam1 (prize: ${jam1PrizeAmount / jam1Winners.length})`
  //         );
  //       }
  //     });
  //     jam2Winners.forEach((winner) => {
  //       if (winner.toLowerCase() === wallet.accounts[0].toLowerCase()) {
  //         setPlayerJam2Amount(jam2PrizeAmount / jam2Winners.length);
  //         console.log(
  //           `> You won Jam2 (prize: ${jam2PrizeAmount / jam2Winners.length})`
  //         );
  //       }
  //     });
  //     jam3Winners.forEach((winner) => {
  //       if (winner.toLowerCase() === wallet.accounts[0].toLowerCase()) {
  //         setPlayerJam3Amount(jam3PrizeAmount / jam3Winners.length);
  //         console.log(
  //           `> You won Jam3 (prize: ${jam3PrizeAmount / jam3Winners.length})`
  //         );
  //       }
  //     });
  //     jam4Winners.forEach((winner) => {
  //       if (winner.toLowerCase() === wallet.accounts[0].toLowerCase()) {
  //         setPlayerJam4Amount(jam4PrizeAmount / jam4Winners.length);
  //         console.log(
  //           `> You won Jam4 (prize: ${jam4PrizeAmount / jam4Winners.length})`
  //         );
  //       }
  //     });
  //     jammyWinners.forEach((winner) => {
  //       if (winner.toLowerCase() === wallet.accounts[0].toLowerCase()) {
  //         setPlayerJammyAmount(jammyPrizeAmount / jammyWinners.length);
  //         console.log(
  //           `> You won Jammy (prize: ${jammyPrizeAmount / jammyWinners.length})`
  //         );
  //       }
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getPrizeAmount = async (prizeIndex, winnerCount) => {
    const totalPot = Number(game[2] * game[4]);
    const share = (
      await contractAndAuth.contract.gamePrizes(gameId, prizeIndex)
    )[0];
    return (
      (Number(totalPot) * share) / contractAndAuth.precisionBasis / winnerCount
    );
  };

  useEffect(() => {
    if (contractAndAuth) {
      dispatch(checkGamePrizes({ dispatch, contractAndAuth, gameId, wallet }))
        .then((result) => {
          console.log("data:", result.payload);
          result.payload.map(async (item, prizeIndex) => {
            if (
              item.winners &&
              item.winners.find(({ addr })=> addr.toLowerCase() === wallet.accounts[0].toLowerCase()) &&
              !prizesWon.includes(prizeIndex)
            ) {
              setPrizesWon((oldArry) => [...oldArry, prizeIndex]);
              getPrizeAmount(prizeIndex, item.winners.length)
                .then((prizeAmount) => {
                  setprizeWonAmounts((oldArry) => [
                    ...oldArry,
                    { prizeIndex, prizeAmount },
                  ]);
                })
                .catch((error) => console.log(error));
              if (prizeIndex === 0) {
                const winner = item.winners.find(({ addr })=> addr.toLowerCase() === wallet.accounts[0].toLowerCase());
                const arrayCard = hexToArry(await contractAndAuth.contract.cards(Number(winner.cardIndex)));
                console.log(Number(winner.cardIndex), arrayCard)
                setJammy([
                  Number(winner.cardIndex),
                  arrayCard,
                ]);
              }
            }
            setIsLoading(false);
          });
        })
        .catch((error) => console.log(error));

      if (!contractAndAuth.isHost) {
        dispatch(allStorageClear({ gameId, wallet }))
          .then((result) => {
            if (result.payload) {
              console.log("all storage clear");
            }
          })
          .catch((error) => console.log(error));
      }
    }
  }, [gameId, contractAndAuth]);

  return (
    <>
      <div className="popup-wrapper">
        <CloseButton
          onClose={onClose}
          to={search}
          extraAction={{
            type: "winnerLoser",
            action: () => (window.location.href = "/"), //cacheleri önlemek için reload
          }}
        />
        <div className="in">
          {!isLoading ? (
            prizesWon.length > 0 ? (
              <div className="text-top-area">
                <img
                  src={
                    winnersImageList[
                      generate_random_number(winnersImageList.length)
                    ]
                  }
                  alt=""
                ></img>
              </div>
            ) : (
              <div className="text-top-area">
                <img
                  src={
                    losersImageList[
                      generate_random_number(losersImageList.length)
                    ]
                  }
                  alt=""
                ></img>
              </div>
            )
          ) : (
            <div className="text-top-area">
              <Loading />
            </div>
          )}

          <div className="card-bottom-area">
            <div className="card-container">
              {!isLoading ? (
                prizesWon.length > 0 ? (
                  prizesWon[0] === 0 ? (
                    <div className="item-card">
                      <span>{jammy.length > 0 && jammy[0]}</span>
                      <div className="in" style={{ margin: "25px 0" }}>
                        {jammy.length > 0 &&
                          jammy[1].map((numbers, numberindex) => (
                            <div
                              className={
                                numbers === 0 ? "item revealed" : "item"
                              }
                              key={numberindex}
                            >
                              {numbers !== 0 ? numbers : null}
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : (
                    <div className="item-card">
                      <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                        <img src={logo} alt="" />
                        <span>{prizesWon[0]}</span>
                        <span>
                          {prizesWon[0] === 1 && "Biggest prize won is 4th JAM"}
                          {prizesWon[0] === 2 && "Biggest prize won is 3rd JAM"}
                          {prizesWon[0] === 3 && "Biggest prize won is 2nd JAM"}
                          {prizesWon[0] === 4 && "Biggest prize won is 1st JAM"}
                        </span>
                      </div>
                    </div>
                  )
                ) : (
                  <div className="item-card">
                    <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                      <img src={logo} alt="" />
                      <span>You lose :(</span>
                    </div>
                  </div>
                )
              ) : (
                <div className="item-card">
                  <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                    <img src={logo} alt="" />
                    <Loading text="Loading card..." />
                  </div>
                </div>
              )}
              <div className="right-area">
                <div className="info-box-wrapper">
                  <div className="profile-info-box">
                    <div className="img">
                      <img
                        // src={`${process.env.REACT_APP_PHOTO_SERVICE}avatars/currentUser.avatar`}
                        src={avatar}
                        alt="user"
                      />
                    </div>
                    <div className="right">
                      <span>username</span>
                      <small>{wallet.accounts[0]}</small>
                    </div>
                  </div>
                </div>
                <div className="buttons">
                  <Link to={search}>
                    <img src={facebookImg} alt="" />
                    <span>Share</span>
                  </Link>
                  <Link to={search}>
                    <img src={twitterImg} alt="" />
                    <span>Tweet</span>
                  </Link>
                  <Link to={search}>
                    <img src={invite2Img} alt="" />
                    <span>Invite</span>
                  </Link>
                </div>
              </div>
              <ul
                style={{ display: "flex", padding: "0px", marginTop: "-15px" }}
              >
                <li>Your Prizes:</li>
                {!isLoading ? (
                  prizeWonAmounts.length > 0 ? (
                    prizeWonAmounts.map((prize, index) => (
                      <li style={{ padding: "0px 6px" }} key={index}>
                        {prize.prizeIndex === 4 && "1st Jam: "}
                        {prize.prizeIndex === 3 && "2nd Jam: "}
                        {prize.prizeIndex === 2 && "3rd Jam: "}
                        {prize.prizeIndex === 1 && "4th Jam: "}
                        {prize.prizeIndex === 0 && "JAMMY: "}
                        {utils.formatEther(prize.prizeAmount.toString())}{" "}
                        {process.env.REACT_APP_NETWORKSYMBOL}
                      </li>
                    ))
                  ) : (
                    <li style={{ padding: "0px 6px" }}>No prizes won</li>
                  )
                ) : (
                  <li style={{ padding: "0px 6px" }}>loading prizes...</li>
                )}
              </ul>
            </div>
          </div>
          <img src={popupBgImg} className="popup-bg" alt="" />
        </div>
      </div>
    </>
  );
};

export default WinnerLoserPopup;
