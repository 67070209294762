import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import { useLocation } from "react-router-dom";
import { useMetaMask } from "../../utility/hooks/useMetaMask";
import {
  urlParams,
  fetchGame,
  checkGamePrizes,
  checkNumbers,
  allStorageClear,
} from "./store";
import { checkGameStatus, setStorage, getStorage, getInfo } from "../store";
import { useDispatch } from "react-redux";
import BottomButtons from "../../components/Game/BottomButtons/BottomButtons";
import Cards from "../../components/Game/Cards/Cards";
import TopInfo from "../../components/Game/TopInfo/TopInfo";
import RightLine from "../../components/Game/RightLine/RightLine";
import LeftLine from "../../components/Game/LeftLine/LeftLine";
import HeadLine from "../../components/HeadLine/HeadLine";
import MockGetRandom from "../../components/MockGetRandom/MockGetRandom";
// Popups
import CardsTxPopup from "../../components/Game/Popups/CardsTxPopup";
import PrizesPopup from "../../components/Game/Popups/PrizesPopup";
import WinnerLoserPopup from "../../components/Game/Popups/WinnerLoserPopup";
import CancelledExpiredPopup from "../../components/Game/Popups/CancelledExpiredPopup";
//img
import confetti from "../../assets/img/confetti-new-number.gif";

function Game() {
  const { search } = useLocation();
  const { wallet, contractAndAuth, unSigner } = useMetaMask();
  const dispatch = useDispatch();
  const [searchParams] = useState(new URLSearchParams(search));
  const [game, setGame] = useState([]);
  const [gameStatus, setGameStatus] = useState(0);
  const [gameId, setGameId] = useState(0);
  const [cardCount, setCartCount] = useState(0);
  const [joinTx, setJoinTx] = useState(null);

  const [drawnNumbers, setDrawnNumbers] = useState([]);
  const passiveNumbers = Array.from(Array(76).keys()).slice(1);
  const [manuelCloseToggle, setManuelCloseToggle] = useState(false); //default > false: auto | true: manuel
  const [gamePrizes, setGamePrizes] = useState([]);

  const [cardChangePopup, setCardChangePopup] = useState(false);
  const initialPrizesPopupParams = { prizeIndex: -1, winners: [] };
  const [prizesPopupParams, setPrizesPopupParams] = useState(
    initialPrizesPopupParams
  );
  const [showWinnerLoserPopup, setShowWinnerLoserPopup] = useState(false);
  const [showCancelledExpired, setShowCancelledExpired] = useState(false);
  const [isCancelExpired, setIsCancelExpired] = useState(null);
  const [newNumber, setNewNumber] = useState(null);

  const newRevealNum = useRef();

  const refundStatus = async (gameId, player) => {
    try {
      const refunds = await contractAndAuth.contract.refunds(gameId, player);
      console.log("refunds:", refunds);
      return refunds;
    } catch (error) {
      console.log(error);
    }
  };

  const checkLocalContractWinners = (contractPrizes) => {
    if (contractPrizes.length > 0) {
      contractPrizes.forEach(async (prize, prizeIndex) => {
        const localWinners = await dispatch(
          getStorage({
            key: `prizes-${gameId}-${Number(prizeIndex)}`,
            type: "local",
          })
        )
          .then((result) => {
            if (result.payload) {
              return result.payload;
            }
          })
          .catch((error) => console.log(error));

        let prizeStatus = false;

        switch (prizeIndex) {
          case 4:
            prizeStatus = prize.isWon;
            break;

          case 3:
            prizeStatus = prize.isWon;
            break;

          case 2:
            prizeStatus = prize.isWon;
            break;

          case 1:
            prizeStatus = prize.isWon;
            break;
        }

        if (prizeStatus) {
          prize.winners.forEach((contractWinner, index) => {
            setTimeout(function () {
              if (!localWinners) {
                console.log("show-popup", index * 6000, contractWinner);
                console.log("1", {
                  prizeIndex,
                  winners: [contractWinner],
                });
                setPrizesPopupParams({
                  prizeIndex,
                  winners: [contractWinner],
                });
              } else {
                //TODO: Hata var düzeltilecek
                // if (
                //   localWinners.length < prize.winners.length &&
                //   !localWinners.find(
                //     ({ addr }) =>
                //       addr.toLowerCase() === contractWinner.addr.toLowerCase()
                //   )
                // ) {
                //   console.log("show-popup", index * 6000, contractWinner);
                //   console.log("2", {
                //     prizeIndex,
                //     winners: [contractWinner],
                //   });
                //   setPrizesPopupParams({
                //     prizeIndex,
                //     winners: [contractWinner],
                //   });
                // }
              }
            }, index * 6000);

            setTimeout(function () {
              if (!localWinners) {
                console.log("close-popup", (index + 1) * 5000, contractWinner);
                setPrizesPopupParams(initialPrizesPopupParams);
              } else {
                //TODO: yukardaki hata düzelince açılacak
                // if (localWinners.length !== prize.winners.length) {
                //   console.log(
                //     "close-popup",
                //     (index + 1) * 5000,
                //     contractWinner
                //   );
                //   setPrizesPopupParams(initialPrizesPopupParams);
                // }
              }
            }, (index + 1) * 5000);
          });
        }
      });
    }
  };

  const contractEvents = async () => {
    gameStatus === 1 &&
      contractAndAuth.contract.on(
        "PlayerJoined",
        async (joinGameId, player, cardsCount) => {
          if (Number(joinGameId) === gameId) {
            console.log("# emmited (START): PlayerJoined");
            console.log("joinGameId:", joinGameId.toString());
            console.log("player:", player.toString());
            console.log("cardsCount:", cardsCount.toString());

            dispatch(checkGameStatus({ gameId, contractAndAuth }))
              .then((result) => {
                if (result.payload) {
                  console.log(">>> gameStatus:", result.payload);
                  setGameStatus(result.payload);
                }
              })
              .catch((error) => console.log(error));

            dispatch(fetchGame({ gameId, contractAndAuth, wallet }))
              .then((result) => {
                if (result.payload) {
                  setGame(result.payload);
                }
              })
              .catch((error) => console.log(error));

            console.log("# emmited (END): PlayerJoined");
          }
        }
      );

    (gameStatus === 1 || gameStatus === 3) &&
      contractAndAuth.contract.on(
        "RequestFulfilled",
        async (requestId, reqType, player) => {
          const reqResult = await contractAndAuth.contract.randomRequests(
            requestId
          );
          if (Number(reqResult.gameId) === gameId) {
            console.log("# emmited (START): RequestFulfilled");
            console.log("reqResult.gameId:", reqResult.gameId.toString());
            console.log("requestId:", requestId.toString());
            console.log("reqType:", reqType.toString());
            console.log("player:", player.toString());

            if (Number(reqType) === 3) {
              const reqResult = await contractAndAuth.contract.randomRequests(
                requestId
              );

              if (Number(reqResult.gameId) === gameId) {
                dispatch(fetchGame({ gameId, contractAndAuth, wallet }))
                  .then((result) => {
                    if (result.payload) {
                      setGame(result.payload);
                    }
                  })
                  .catch((error) => console.log(error));

                dispatch(checkGameStatus({ gameId, contractAndAuth }))
                  .then((result) => {
                    if (result.payload) {
                      setGameStatus(result.payload);
                    }
                  })
                  .catch((error) => console.log(error));
              }
            }
            console.log("# emmited (END): RequestFulfilled");
          }
        }
      );

    (gameStatus === 2 || gameStatus === 3) &&
      contractAndAuth.contract.on("GameStarted", async (startedGameId) => {
        if (Number(startedGameId) === gameId) {
          console.log("# emmited (START): GameStarted");
          console.log("startedGameId:", startedGameId.toString());

          dispatch(checkGameStatus({ gameId, contractAndAuth }))
            .then((result) => {
              if (result.payload) {
                console.log(">>> gameStatus:", result.payload);
                setGameStatus(result.payload);
                localStorage.removeItem(
                  `join-${gameId}-${wallet.accounts[0].toLowerCase()}`
                );
              }
            })
            .catch((error) => console.log(error));

          console.log("# emmited (END): GameStarted");
        }
      });

    gameStatus === 3 &&
      contractAndAuth.contract.on(
        "SetTxHash",
        async (txGameId, revealedNumber, txHash, datetime) => {
          if (Number(txGameId) === gameId) {
            console.log(
              "SetTxHash:",
              revealedNumber.toString(),
              txHash,
              Number(datetime)
            );

            setDrawnNumbers((oldDrawnNumbers) => [
              ...oldDrawnNumbers,
              !oldDrawnNumbers.find(
                ({ number }) => number === Number(revealedNumber)
              ) && {
                datetime: Number(datetime),
                number: Number(revealedNumber),
                transaction: txHash,
              },
            ]);

            if (!contractAndAuth.isHost) {
              dispatch(
                checkGamePrizes({ dispatch, contractAndAuth, gameId, wallet })
              )
                .then((result) => {
                  if (result.payload) {
                    setGamePrizes(result.payload);
                    checkLocalContractWinners(result.payload); // açılmayan popuplar için kontrol edilir.
                  }
                })
                .catch((error) => console.log(error));
            }

            const localRN = await dispatch(
              getStorage({
                key: `revealNumbers-${gameId}`,
                type: "local",
              })
            )
              .then((result) => {
                return result.payload || [];
              })
              .catch((error) => console.log(error));

            if (!localRN.find(({ num }) => num === Number(revealedNumber))) {
              if (!contractAndAuth.isHost) {
                setNewNumber(Number(revealedNumber));
                newRevealNum.current.style.display = "block";
                setTimeout(() => {
                  // setNewNumber(null);
                  newRevealNum.current.style.display = "none";
                }, 3500);
              }
              localRN.push({
                datetime: Number(datetime),
                number: Number(revealedNumber),
                transaction: txHash,
              });
              dispatch(
                setStorage({
                  key: `revealNumbers-${gameId}`,
                  value: localRN,
                  type: "local",
                })
              );
              console.log("localRN event:", localRN);
            }

            //TODO: fe contract senkron mu bakılacak cekilen sayı adeti aynı olmalı contract info kullanılabilir
            const info = await dispatch(
              getInfo({ dispatch, contractAndAuth, gameId, wallet })
            )
              .then((result) => {
                if (result.payload) {
                  return result.payload;
                }
              })
              .catch((error) => {
                console.log(error);
              });

            const localNumbers = await dispatch(
              getStorage({
                key: `revealNumbers-${gameId}`,
                type: "local",
              })
            )
              .then((result) => {
                return result.payload || [];
              })
              .catch((error) => console.log(error));

            console.log(
              "localRN:",
              localNumbers.length,
              "contractRN:",
              Number(info.revealedNumberLength)
            );
            if (localNumbers.length !== Number(info.revealedNumberLength)) {
              console.log(">>> FE and Contract out of sync");
              dispatch(
                checkNumbers({ dispatch, gameId, contractAndAuth, gameStatus })
              )
                .then((result) => {
                  if (result.payload) {
                    setDrawnNumbers(result.payload.drawnNumbers);
                  }
                })
                .catch((error) => console.log(error));
            } else {
              console.log(">>> FE and Contract synchronized");
            }
          }
        }
      );

    gameStatus === 3 &&
      contractAndAuth.contract.on(
        "PrizeWon",
        async (prizeWonGameId, prizeIndex, winner, wonCardIndex) => {
          if (Number(prizeWonGameId) === gameId) {
            console.log("# emmited (START): PrizeWon");
            console.log("prizeWonGameId:", prizeWonGameId.toString());
            console.log("prizeIndex:", prizeIndex.toString());
            console.log("winner:", winner.toString());

            dispatch(checkGameStatus({ gameId, contractAndAuth }))
              .then((result) => {
                if (result.payload) {
                  console.log(">>> gameStatus:", result.payload);
                  setGameStatus(result.payload);
                }
              })
              .catch((error) => console.log(error));

            if (!contractAndAuth.isHost) {
              Number(prizeIndex) !== 0 &&
                setPrizesPopupParams({
                  prizeIndex: Number(prizeIndex),
                  winners: [{ addr: winner, cardIndex: wonCardIndex }],
                });
            } else {
              if (
                Number(prizeIndex) === 0 &&
                !localStorage.getItem(`jammy-${game[0]}`)
              ) {
                dispatch(
                  setStorage({
                    key: `jammy-${game[0]}`,
                    value: true,
                    type: "local",
                  })
                );
              }
            }

            console.log("# emmited (END): PrizeWon");
          }
        }
      );

    (gameStatus === 3 || gameStatus === 4) &&
      contractAndAuth.contract.on("GameEnds", async (endsGameId) => {
        if (Number(endsGameId) === gameId) {
          console.log("# emmited (START): GameEnds");
          console.log("endsGameId:", endsGameId.toString());

          dispatch(
            checkGamePrizes({ dispatch, contractAndAuth, gameId, wallet })
          )
            .then((result) => {
              if (result.payload) {
                setGamePrizes(result.payload);
              }
            })
            .catch((error) => console.log(error));

          dispatch(checkGameStatus({ gameId, contractAndAuth }))
            .then((result) => {
              if (result.payload) {
                console.log(">>> gameStatus:", result.payload);
                setGameStatus(result.payload);
              }
            })
            .catch((error) => console.log(error));

          let winners = [];
          try {
            for (let i = 0; ; i++) {
              winners.push(
                await contractAndAuth.contract.prizeWinners(gameId, 0, i)
              );
            }
          } catch (error) {
            setPrizesPopupParams({ prizeIndex: 0, winners: winners });
          }
        }
      });

    gameStatus === 4 &&
      contractAndAuth.contract.on(
        "PrizeCollected",
        async (prizeCollectedGameId, totalAmount) => {
          if (Number(prizeCollectedGameId) === gameId) {
            console.log("# emmited (START): PrizeCollected");
            console.log("totalAmount:", totalAmount);

            if (!contractAndAuth.isHost) {
              dispatch(allStorageClear({ gameId, wallet }))
                .then((result) => {
                  if (result.payload) {
                    console.log("all storage clear");
                  }
                })
                .catch((error) => console.log(error));
            }

            if (!showWinnerLoserPopup) {
              setPrizesPopupParams(initialPrizesPopupParams);
              setShowWinnerLoserPopup(true);
            }

            console.log("# emmited (END): PrizeCollected");
          }
        }
      );

    gameStatus !== 0 &&
      contractAndAuth.contract.on("GameCancelled", async (cancelledGameId) => {
        if (Number(cancelledGameId) === gameId) {
          console.log("# emmited (START): GameCancelled");
          console.log("cancelledGameId:", cancelledGameId.toString());

          dispatch(checkGameStatus({ gameId, contractAndAuth }))
            .then((result) => {
              if (result.payload) {
                console.log(">>> gameStatus:", result.payload);
                setGameStatus(result.payload);
              }
            })
            .catch((error) => console.log(error));

          refundStatus(gameId, wallet.accounts[0])
            .then((result) => {
              if (!result) {
                setIsCancelExpired("cancelled");
                setShowCancelledExpired(true);
              }
            })
            .catch((error) => console.log(error));

          console.log("# emmited (END): GameCancelled");
        }
      });
  };

  useEffect(() => {
    if (unSigner.contract) {
      unSigner.contract.on("CardsAdded", async (amount, newCount) => {
        console.log("CardsAdded event was emmited");
        dispatch(getInfo({ dispatch, unSigner }));
      });
      unSigner.contract.on("CardsUpdated", async (amount) => {
        console.log("CardsUpdated event was emmited");
        dispatch(getInfo({ dispatch, unSigner }));
      });
    }
  }, [unSigner]);

  useEffect(() => {
    if (typeof window.ethereum !== "undefined") {
      if (!gameId || !cardCount) {
        dispatch(urlParams({ searchParams }))
          .then((result) => {
            console.log(result.payload);
            setGameId(result.payload.gameId);
            setCartCount(result.payload.cardCount);
          })
          .catch((error) => console.log(error));
      }

      try {
        if (
          gameId > 0 &&
          wallet.accounts.length > 0 &&
          contractAndAuth.contract
        ) {
          //cardTx popup açılışı
          dispatch(
            getStorage({
              key: `join-${gameId}-${wallet.accounts[0].toLowerCase()}`,
              type: "local",
            })
          )
            .then((result) => {
              if (result.payload) {
                setJoinTx(result.payload.split("-")[2]);
              }
            })
            .catch((error) => console.log(error));

          dispatch(fetchGame({ dispatch, gameId, contractAndAuth, wallet }))
            .then((result) => {
              if (result.payload) {
                console.log("game:", result.payload);
                setGame(result.payload);
              }
            })
            .catch((error) => console.log(error));

          dispatch(checkGameStatus({ gameId, contractAndAuth }))
            .then((result) => {
              if (result.payload) {
                console.log("gameStatus:", result.payload);
                setGameStatus(result.payload);
              }
            })
            .catch((error) => console.log(error));

          if (gameStatus === 1) {
            console.log(">>> GAME CREATED");
          } else if (gameStatus === 2) {
            console.log(">>> GAME HAS NOT STARTED YET");
          } else if (gameStatus === 3 && game[5] > 1) {
            console.log(">>> GAME STARTED");
            dispatch(
              checkNumbers({ dispatch, gameId, contractAndAuth, gameStatus })
            )
              .then((result) => {
                if (result.payload) {
                  setDrawnNumbers(result.payload.drawnNumbers);
                }
              })
              .catch((error) => console.log(error));
            if (!contractAndAuth.isHost) {
              dispatch(
                checkGamePrizes({ dispatch, contractAndAuth, gameId, wallet })
              )
                .then((result) => {
                  if (result.payload) {
                    setGamePrizes(result.payload);
                    checkLocalContractWinners(result.payload); // açılmayan popuplar için kontrol edilir.
                  }
                })
                .catch((error) => console.log(error));
            }
          } else if (gameStatus === 4) {
            console.log(">>> GAME ENDED");
          } else if (gameStatus === 5) {
            console.log(">>> GAME EXPIRED");
            refundStatus(gameId, wallet.accounts[0])
              .then((result) => {
                if (!result) {
                  setIsCancelExpired("expired");
                  setShowCancelledExpired(true);
                }
              })
              .catch((error) => console.log(error));
          } else if (gameStatus === 6) {
            console.log(">>> GAME CANCELED");
            refundStatus(gameId, wallet.accounts[0])
              .then((result) => {
                if (!result) {
                  setIsCancelExpired("cancelled");
                  setShowCancelledExpired(true);
                }
              })
              .catch((error) => console.log(error));
          }

          contractEvents();
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [
    wallet.accounts,
    contractAndAuth.contract,
    gameId,
    cardCount,
    gameStatus,
  ]);

  return (
    <>
      <div
        className="new-reveal-number"
        ref={newRevealNum}
        // style={newNumber !== null ? { display: "block" } : { display: "none" }}
      >
        <h1>{newNumber}</h1>
      </div>
      {Number(process.env.REACT_APP_NETWORKVERSION) === 31337 && (
        <MockGetRandom />
      )}
      <HeadLine />
      <div className="wrapper">
        <LeftLine
          drawnNumbers={drawnNumbers}
          passiveNumbers={passiveNumbers.sort(function (a, b) {
            return a - b;
          })}
          gameStatus={gameStatus}
        />
        <div className="item-center col-7 col-xl-6">
          <TopInfo game={game} />
          <Cards
            gameId={gameId}
            game={game}
            gameStatus={gameStatus}
            wallet={wallet}
            contractAndAuth={contractAndAuth}
            drawnNumbers={drawnNumbers}
            manuelCloseToggle={manuelCloseToggle}
            cardChangePopup={cardChangePopup}
            setCardChangePopup={setCardChangePopup}
          />
          <BottomButtons
            gameId={gameId}
            cardCount={cardCount}
            gameStatus={gameStatus}
            wallet={wallet}
            contractAndAuth={contractAndAuth}
            drawnNumbers={drawnNumbers}
            gamePrizes={gamePrizes}
            setCardChangePopup={setCardChangePopup}
            manuelCloseToggle={manuelCloseToggle}
            setManuelCloseToggle={setManuelCloseToggle}
          />
        </div>
        <RightLine
          gameId={gameId}
          game={game}
          gameStatus={gameStatus}
          contractAndAuth={contractAndAuth}
          drawnNumbers={drawnNumbers}
        />
      </div>

      {joinTx && (
        <CardsTxPopup
          gameId={gameId}
          contractAndAuth={contractAndAuth}
          wallet={wallet}
          hash={joinTx}
          onClose={() => {
            localStorage.removeItem(
              `join-${gameId}-${wallet.accounts[0].toLowerCase()}`
            );
            setJoinTx(null);
          }}
        />
      )}
      {prizesPopupParams.prizeIndex !== -1 && !contractAndAuth.isHost && (
        <PrizesPopup
          gameId={gameId}
          contractAndAuth={contractAndAuth}
          prizesPopupParams={prizesPopupParams}
          setShowWinnerLoserPopup={setShowWinnerLoserPopup}
          onClose={() => setPrizesPopupParams(initialPrizesPopupParams)}
        />
      )}
      {showWinnerLoserPopup && !contractAndAuth.isHost && (
        <WinnerLoserPopup
          gameId={gameId}
          game={game}
          contractAndAuth={contractAndAuth}
          wallet={wallet}
          onClose={() => setShowWinnerLoserPopup(false)}
        />
      )}
      {showCancelledExpired && !contractAndAuth.isHost && (
        <CancelledExpiredPopup
          gameId={gameId}
          isCancelExpired={isCancelExpired}
          onClose={() => setShowCancelledExpired(false)}
        />
      )}
    </>
  );
}
export default Game;
