// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add_pop {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
  }
  .add_pop img{
    width: 100%;
    height: 100%;
  }
  .ads_top {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .add_close {
    margin-left: -1px;
    margin-top: -2px;
    background: #171717;
    padding: 0px 7px;
    border-radius: 50%;
    cursor: pointer;
  }
  .timers {
    text-align: right;
    margin-right: 5px;
    color: #171717;
  }`, "",{"version":3,"sources":["webpack://./src/components/AdsOpen/AdsOpen.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,YAAY;EACd;EACA;IACE,WAAW;IACX,YAAY;EACd;EACA;IACE,kBAAkB;IAClB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;EACb;EACA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;EACjB;EACA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;EAChB","sourcesContent":[".add_pop {\n    position: fixed;\n    z-index: 999;\n    width: 100%;\n    height: 100%;\n  }\n  .add_pop img{\n    width: 100%;\n    height: 100%;\n  }\n  .ads_top {\n    position: absolute;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n  }\n  .add_close {\n    margin-left: -1px;\n    margin-top: -2px;\n    background: #171717;\n    padding: 0px 7px;\n    border-radius: 50%;\n    cursor: pointer;\n  }\n  .timers {\n    text-align: right;\n    margin-right: 5px;\n    color: #171717;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
