// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { utils } from "ethers";
import { hexToArry } from "../../../components/CreateCards";
import { setStorage, getStorage, getInfo } from "../../store";

export const allStorageClear = createAsyncThunk(
  "allStorageClear",
  async ({ gameId, wallet }) => {
    localStorage.removeItem(`join-${gameId}`);
    localStorage.removeItem(
      `redraw-${gameId}-${wallet.accounts[0].toLowerCase()}-0`
    );
    localStorage.removeItem(
      `redraw-${gameId}-${wallet.accounts[0].toLowerCase()}-1`
    );
    localStorage.removeItem(
      `redraw-${gameId}-${wallet.accounts[0].toLowerCase()}-2`
    );
    localStorage.removeItem(
      `redraw-${gameId}-${wallet.accounts[0].toLowerCase()}-3`
    );
    localStorage.removeItem(`closeNumber-${gameId}-0`);
    localStorage.removeItem(`closeNumber-${gameId}-1`);
    localStorage.removeItem(`closeNumber-${gameId}-2`);
    localStorage.removeItem(`closeNumber-${gameId}-3`);
    localStorage.removeItem(`prizes-${gameId}-4`);
    localStorage.removeItem(`prizes-${gameId}-3`);
    localStorage.removeItem(`prizes-${gameId}-2`);
    localStorage.removeItem(`prizes-${gameId}-1`);
    localStorage.removeItem(`prizes-${gameId}-0`);
    localStorage.removeItem(`startgame-${gameId}`);
    localStorage.removeItem(`revealNumbers-${gameId}`);
    localStorage.removeItem(`jammy-${gameId}`);

    return true;
  }
);

export const urlParams = createAsyncThunk(
  "urlParams",
  async ({ searchParams }) => {
    const gameId = Number(searchParams.get("gameId"));
    const cardCount = Number(searchParams.get("cards"));
    return { gameId, cardCount };
  }
);

export const fetchGame = createAsyncThunk(
  "fetchGame",
  async ({ dispatch, contractAndAuth, gameId, wallet }) => {
    const games = await contractAndAuth.contract.games(gameId);
    const gameHost = games.host.toLowerCase();
    const gametimestamp = Number(games.startDate);
    const gamecardprice = Number(games.cardPrice); //TODO: Number dönüşümü olmayabilir bakılacak
    const maxCardsPerPlayer = Number(games.maxCardsPerPlayer);
    const totalCardsSold = Number(games.totalCardsSold);
    const houseShare = Number(games.houseShare);
    const gameSeed = Number(games.seed);
    const cancelled = games.cancelled;
    const totalPlayerCount = Number(games.totalPlayerCount);

    const game = [
      gameId, //index 0
      gametimestamp, //index 1
      gamecardprice, //index 2
      maxCardsPerPlayer, //index 3
      totalCardsSold, //index 4
      gameSeed, //index 5
      houseShare, //index 6
      gameHost, //index 7
      cancelled, //index 8
      totalPlayerCount,
    ];
    return game;
  }
);

export const getCard = createAsyncThunk(
  "getCard",
  async ({ dispatch, gameId, contractAndAuth, wallet, playerCardIndex }) => {
    let cardIndex = null;
    let errors = [];

    try {
      cardIndex = Number(
        await contractAndAuth.contract.playerCards(
          gameId,
          wallet.accounts[0].toLowerCase(),
          playerCardIndex
        )
      );

      if (Number(cardIndex) !== 0) {
        const cardHex = await contractAndAuth.contract.cards(cardIndex);

        localStorage.removeItem(
          `redraw-${gameId}-${wallet.accounts[0].toLowerCase()}-${playerCardIndex}`
        );

        return {
          cardIndex,
          cardNumbers: hexToArry(cardHex),
          errors: null,
        };
      } else {
        errors.push("card not ready");
        return { cardIndex: null, cardNumbers: null, errors: errors };
      }
    } catch (error) {
      errors.push("card not found");
      return { cardIndex, cardNumbers: null, errors: errors };
    }
  }
);

export const redrawCard = createAsyncThunk(
  "redrawCard",
  async ({
    dispatch,
    gameId,
    contractAndAuth,
    wallet,
    playerCardIndex,
    gameStatus,
    game,
  }) => {
    let errors = [];
    let hash = null;

    if (gameStatus === 1) {
      try {
        const redrawValue = utils.formatEther((game[2] / 2).toString());
        console.log(redrawValue);
        const tx = await contractAndAuth.contract.redrawCard(
          gameId,
          playerCardIndex,
          {
            value: utils.parseEther(redrawValue),
          }
        );
        dispatch(
          setStorage({
            key: `redraw-${gameId}-${wallet.accounts[0].toLowerCase()}-${playerCardIndex}`,
            value: "start-",
            type: "local",
          })
        );
        const receipt = await tx.wait();
        console.log("receipt-redrawCard:", receipt);

        if (receipt.events.length > 0) {
          receipt.events.forEach((element) => {
            if (element.event && element.event === "RequestSent") {
              hash = receipt.transactionHash;
              dispatch(
                setStorage({
                  key: `redraw-${gameId}-${wallet.accounts[0].toLowerCase()}-${playerCardIndex}`,
                  value: `wait-${element.args.requestId}`,
                  type: "local",
                })
              );
              localStorage.removeItem(
                `card-${gameId}-${wallet.accounts[0].toLowerCase()}-${playerCardIndex}`
              );
            }
          });
        }
      } catch (err) {
        errors.push(err.reason);
        localStorage.removeItem(
          `redraw-${gameId}-${wallet.accounts[0].toLowerCase()}-${playerCardIndex}`
        );
      }
    } else {
      errors.push("redrawCards: wrongGameStatus");
    }
    return { hash, errors };
  }
);

export const checkGamePrizes = createAsyncThunk(
  "checkGamePrizes",
  async ({ dispatch, contractAndAuth, gameId, wallet }) => {
    let prizeObj = [];

    const info = await dispatch(
      getInfo({ dispatch, contractAndAuth, gameId, wallet })
    )
      .then((result) => {
        if (result.payload) {
          return result.payload;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    const gamePrizesLength = Number(info.gamePrizesLength);

    for (let prizeIndex = 0; prizeIndex < gamePrizesLength; prizeIndex++) {
      //prizeIndex: 0 > jammy, 1 > jam4, 2 > jam3, 3 > jam2, 4 > jam1
      const info = await dispatch(
        getInfo({ dispatch, contractAndAuth, gameId, wallet, prizeIndex })
      )
        .then((result) => {
          if (result.payload) {
            return result.payload;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      const prizeWinnersLength = Number(info.prizeWinnersLength);

      let winners = [];
      const isWon = (
        await contractAndAuth.contract.gamePrizes(gameId, prizeIndex)
      ).won;

      for (
        let winnerIndex = 0;
        winnerIndex < prizeWinnersLength;
        winnerIndex++
      ) {
        const winner = await contractAndAuth.contract.prizeWinners(
          gameId,
          prizeIndex,
          winnerIndex
        );
        winners.push(winner);
      }

      prizeObj.push({ prizeIndex, isWon, winners });
    }

    return prizeObj;
  }
);

export const closeNumber = createAsyncThunk(
  "closeNumber",
  async ({
    dispatch,
    gameId,
    contractAndAuth,
    gameStatus,
    playerCardIndex,
    number,
    card0DrawnNumbers,
    card1DrawnNumbers,
    card2DrawnNumbers,
    card3DrawnNumbers,
  }) => {
    let errors = [];
    if (gameStatus === 3) {
      let closedNumbers =
        (await dispatch(
          getStorage({
            key: `closeNumber-${gameId}-${playerCardIndex}`,
            type: "local",
          })
        )
          .then((result) => {
            if (result.payload) {
              return result.payload;
            }
          })
          .catch((error) => console.log(error))) || [];

      const tx = (await contractAndAuth.contract.drawnNumbers(gameId, number))
        .state; //kontrat tarafında sayının cekilip cekilmedigi kontrol ediliyor.
      if (tx) {
        if (
          (card0DrawnNumbers && !card0DrawnNumbers.includes(number)) ||
          (card1DrawnNumbers && !card1DrawnNumbers.includes(number)) ||
          (card2DrawnNumbers && !card2DrawnNumbers.includes(number)) ||
          (card3DrawnNumbers && !card3DrawnNumbers.includes(number))
        ) {
          closedNumbers.push(number);
          await dispatch(
            setStorage({
              key: `closeNumber-${gameId}-${playerCardIndex}`,
              value: closedNumbers,
              type: "local",
            })
          );
          return { closedNumbers, errors: null };
        } else {
          errors.push(`'${number}' is closed!`);
          return { closedNumbers: null, errors: errors };
        }
      } else {
        errors.push(`'${number}' is not drawn!`);
        return { closedNumbers: null, errors: errors };
      }
    } else {
      errors.push(`closeNumber: wrongGameStatus (${gameStatus})`);
      return { closedNumbers: null, errors: errors };
    }
  }
);

export const checkNumbers = createAsyncThunk(
  "checkNumbers",
  async ({ dispatch, gameId, contractAndAuth, gameStatus }) => {
    let drawnNumbers = [];

    if (gameStatus === 3) {
      localStorage.removeItem(`revealNumbers-${gameId}`);

      for (let i = 1; i <= 75; i++) {
        const resultDrawnState = (
          await contractAndAuth.contract.drawnNumbers(gameId, i)
        ).state;
        const resultDrawnHash = (
          await contractAndAuth.contract.drawnNumbers(gameId, i)
        ).txHash;
        const datetime = Number(
          (await contractAndAuth.contract.drawnNumbers(gameId, i)).datetime
        );
        if (resultDrawnState === true) {
          drawnNumbers.push({
            datetime,
            number: i,
            transaction: resultDrawnHash,
          });
        }
      }
      
      dispatch(
        setStorage({
          key: `revealNumbers-${gameId}`,
          value: drawnNumbers,
          type: "local",
        })
      );
    } else {
      console.log(`checkNumbers: wrongGameStatus (${gameStatus})`);
    }

    return { drawnNumbers };
  }
);

//TODO: manuel sayı kapatmada bottombuttons (winPrize) bağlı olarak karşılıklı iyileştirilecek.
//fe ye yeni sayı gelmeden sayıyı kapatması ve winprize yapabilmesi engellenecek.
export const checkWinCard = createAsyncThunk(
  "checkWinCard",
  async ({
    dispatch,
    gameId,
    cardCount,
    contractAndAuth,
    wallet,
    prizeIndex,
    drawnNumbers,
    isManuelCloseCard,
  }) => {
    console.log("isManuelCloseCard:", isManuelCloseCard, cardCount);
    let isFound = false;
    let pci = 0;
    let cardIndex,
      localDrawnNumbers = null;

    for (pci; pci < cardCount; pci++) {
      if (isManuelCloseCard) {
        localDrawnNumbers = await dispatch(
          getStorage({
            key: `closeNumber-${gameId}-${pci}`,
            type: "local",
          })
        )
          .then((result) => {
            if (result.payload) {
              console.log("localDrawnNumbers:", pci, result.payload);
              return result.payload;
            }
          })
          .catch((error) => console.log(error));
      }

      try {
        cardIndex = Number(
          await contractAndAuth.contract.playerCards(
            gameId,
            wallet.accounts[0],
            pci
          )
        );
        
        const hexCard = await contractAndAuth.contract.cards(cardIndex);
        const arrayCard = hexToArry(hexCard);

        const rowsToMatch = 5 - prizeIndex;
        let matchedRows = 0;
        let seenNum = 0;
        let rowIndex = 0;
        let winRowIndex = 0;

        arrayCard.forEach((cardNum, cardNumIndex) => {
          if (cardNum === 0) seenNum++;
          if (isManuelCloseCard) {
            if (localDrawnNumbers) {
              localDrawnNumbers.forEach((drawnNum) => {
                if (Number(cardNum) === Number(drawnNum)) {
                  seenNum++;
                }
              });
            }
          } 
          // else {
          //   drawnNumbers.forEach((drawnNum) => {
          //     if (Number(cardNum) === Number(drawnNum.number)) {
          //       seenNum++;
          //     }
          //   });
          // }

          if (cardNumIndex % 5 === 4) {
            if (seenNum === 5) {
              matchedRows++;
              winRowIndex = rowIndex;
            }
            seenNum = 0;
            rowIndex++;
          }
        });
        console.log(pci, cardIndex ,matchedRows, rowsToMatch)

        if (matchedRows >= rowsToMatch) {
          isFound = true;
          break;
        }
      } catch (error) {
        console.log(error.reason);
        break;
      }
    }

    return {
      isFound,
      avatar: "avatar6.png",
      username: "username",
      playerCardIndex: pci,
      cardIndex,
      drawnNumbers: isManuelCloseCard ? localDrawnNumbers : drawnNumbers,
    };
  }
);

export const gameStore = createSlice({
  name: "gameStore",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(urlParams.fulfilled, (state, action) => {
      state.gameId = action.payload.gameId;
      state.cardCount = action.payload.cardCount;
    });

    builder.addCase(fetchGame.fulfilled, (state, action) => {
      state.game = action.payload;
    });
  },
});

export default gameStore.reducer;
