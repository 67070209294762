import React, { useState, useEffect } from "react";
import { useMetaMask } from "../../utility/hooks/useMetaMask";
import Web3 from "web3";
import { ethers } from "ethers";

function RpcTest() {
  const { wallet, contractAndAuth } = useMetaMask();
  const [txId, setTxId] = useState(null);

  const hostAddress =
    Number(process.env.REACT_APP_NETWORKVERSION) === 31337
      ? "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266"
      : "0xa8fb9234fD4a2d1757766161c74f27f0de5aA6e2";

  const API_KEY = process.env.REACT_APP_INFURA_API_KEY;
  const PRIVATE_KEY = process.env.REACT_APP_DEPLOYER_PRIVKEY;
  const provider_Metamask = new ethers.providers.Web3Provider(window.ethereum);
  const infuraProvider = new ethers.providers.InfuraProvider(
    "sepolia",
    API_KEY
  );

  const [blockNumber, setBlockNumber] = useState(null);
  const [txSent, setTxSent] = useState(null);
  const [txSentInfura, setTxSentInfura] = useState(null);

  const handleButton1 = async () => {
    const latest_block = await infuraProvider.getBlockNumber("latest");
    setBlockNumber(latest_block);
  };
  
  const handleButton2 = async () => {
    const latest_block = await provider_Metamask.getBlockNumber("latest");
    setBlockNumber(latest_block);
  };

  const handleSubmitWeb3 = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const address = data.get("address");
    const amount = data.get("amount");
    sendTransaction(address, amount);
  };
  
  const handleSubmitInfura = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const address = data.get("address");
    const amount = data.get("amount");
    const signer = new ethers.Wallet(PRIVATE_KEY, infuraProvider);
    sendTransaction(address, amount, signer);
  };

  const sendTransaction = async (address, amount, signer=null) => {
    if (signer==null){ // Web3 Provider
      if (!window.ethereum)
        console.error("No wallet found!");
      else {
        await window.ethereum.send("eth_requestAccounts");
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tx = await signer.sendTransaction({
          to: address,
          value: ethers.utils.parseEther(amount)
        });
        console.log("tx", tx);
        setTxSent('Transaction initiated! Tx hash: ' + tx.hash);
      }
    }
    else // InfuraProvider
    {
      const tx = await signer.sendTransaction({
        to: address,
        value: ethers.utils.parseEther(amount)
      });
      console.log("tx", tx);
      setTxSentInfura('Transaction initiated! Tx hash: ' + tx.hash);
    }
  }

  const setHost = async () => {
    try {
      if (typeof window.ethereum !== "undefined") {
        await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [
            {
              to: "0x4B0897b0513FdBeEc7C469D9aF4fA6C0752aBea7",
              from: "0xa8fb9234fd4a2d1757766161c74f27f0de5aa6e2",
              gas: "0x76c0",
              value: "0x8ac7230489e80000",
              data: "0x",
              gasPrice: "0x4a817c800",
            },
          ],
        });
        // const tx = await contractAndAuth.contract.setHost(hostAddress, true);
        // const receipt = await tx.wait();
        // setTxId(receipt.transactionHash);
        // console.log("receipt:", receipt);
      }
    } catch (error) {
      console.error(error.reason);
    }
  };

  const fetchLogs = async (contractAndAuth) => {
    //mainnet: https://mainnet.infura.io/v3/6c8a3e6125ad43299d1a61135ef7cd72
    const eventSignature2 = ethers.utils.id("HostSet(address,bool)");

    const filterOptions = {
      fromBlock: "latest", // Start from the genesis block
      address: contractAndAuth.contract.address,
      topics: [eventSignature2],
    };

    // Fetch logs using the filter options
    console.log(await contractAndAuth.provider.getLogs(filterOptions));
    // web3.eth
    //   .getPastLogs(filterOptions)
    //   .then((logs) => {
    //     logs.forEach((log) => {
    //       console.log("log:", log);
    //     });
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //   });
  };

  useEffect(() => {
    if (typeof window.ethereum !== "undefined") {
      try {
        if (wallet.accounts.length > 0 && contractAndAuth.contract) {
          console.log("if:", contractAndAuth);
          if (
            wallet.accounts[0].toLowerCase() !==
            contractAndAuth.deployer.toLowerCase()
          ) {
            window.location.href = "/";
          }
        } else {
          console.log("else:", contractAndAuth);
        }
      } catch (error) {
        console.error(error.reason);
      }
    }
  }, [contractAndAuth]);

  useEffect(() => {
    console.log("events useEffect 1");
    if (contractAndAuth.contract) {
      console.log("events useEffect 2", contractAndAuth);
      // fetchLogs(contractAndAuth);
      // const blockNumber = contractAndAuth.provider.getBlockNumber().then(async (payload) => {
      //   return payload;
      // })
      // console.log("blockNumber:", blockNumber);

      const eventSignature2 = ethers.utils.id("HostSet(address,bool)");
      const filterOptions = {
        // fromBlock: "latest", // Start from the genesis block
        // toBlock: "latest", // End at the current block
        address: contractAndAuth.contract.address,
        topics: [eventSignature2],
      };

      contractAndAuth.contract.on(filterOptions, (account, state) => {
        console.log("HostSet event was emmited --");
        console.log("account:", account.toString());
        console.log("state:", state.toString());
      });

      // contractAndAuth.contract.once("CardsUpdated", (amount) => {
      //   console.log("CardsUpdated event was emmited");
      //   console.log("amount:", amount.toString());
      // });
    }
  }, [contractAndAuth]);

  return (
    <div className="AppPanel" style={{ textAlign: "center" }}>
      {/* <header
        style={{
          backgroundColor: "#444",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: "#000",
        }}
      >
        <div>
          <h4>Protocol Setups</h4>
          <div
            style={{
              border: "2px solid #fff",
              padding: "10px",
              margin: "4px",
              borderRadius: "12px",
            }}
          >
            <button
              onClick={setHost}
              style={{ color: "#369", padding: "0 8px", borderRadius: "6px" }}
            >
              setHost (admin)
            </button>
          </div>
        </div>
        <p
          style={{
            fontSize: "11pt",
            border: "2px solid #fff",
            borderRadius: "12px",
            padding: "6px 12px",
          }}
        >
          TX Hash: <span style={{ color: "#ddd" }}>{txId}</span>
        </p>
      </header> */}

      <header style={{
          backgroundColor: "#444",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: "#000",
        }}>
          {/* 0x3C870902ec71E3ef2B4dE4b13c55044C698922d6 */}
      <h3> Press one of the buttons to find out the latest block number: </h3>
      <div>
        <button onClick={handleButton1} style={{color: 'darkblue'}}>InfuraProvider</button>
        <button onClick={handleButton2} style={{color: 'darkblue'}}>Web3Provider</button>
        <p>{blockNumber}</p>
      </div>
      <h3> Fill out the form to send a transaction via Web3Provider: </h3>
      <div>
        <form onSubmit={handleSubmitWeb3}>
          <input type="text" name="address" placeholder="Recipient Address" style={{color: 'darkblue'}} />
          <input type="text" name="amount" placeholder="Amount (ETH)" style={{color: 'darkblue'}} />
          <input type="submit" value="Send w/ Web3Provider" style={{color: 'darkblue'}} />
        </form>
        <p>{txSent}</p>
      </div>
      <h3> Fill out the form to send a transaction via InfuraProvider: </h3>
      <div>
        <form onSubmit={handleSubmitInfura}>
          <input type="text" name="address" placeholder="Recipient Address" style={{color: 'darkblue'}} />
          <input type="text" name="amount" placeholder="Amount (ETH)" style={{color: 'darkblue'}} />
          <input type="submit" value="Send w/ InfuraProvider" style={{color: 'darkblue'}} />
        </form>
        <p>{txSentInfura}</p>
      </div>
    </header>
    </div>
  );
}

export default RpcTest;
