// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BigNumber, utils } from "ethers";

const dateTime = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ap = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  // if (hours === 12) {
  //   hours = 0;
  // }
  let mergeTime =
    hours.toString().padStart(2, "0") +
    ":" +
    minutes.toString().padStart(2, "0") +
    " " +
    ap;
  return mergeTime;
};

// const gameStatus = async (gameId, contractAndAuth) => {
//   const jammyWon = (await contractAndAuth.contract.gamePrizes(gameId, 0))[1];
//   const game = await contractAndAuth.contract.games(gameId);
//   const nowTimestamp = Math.floor(Date.now() / 1000);
//   const gameTimestamp = Number(game[1]);
//   const gameCancelled = game[7];
//   const gameSeed = game[6];

//   if (gameTimestamp === 0) {
//     //GameStatus.INVALID
//     return 0;
//   }
//   if (jammyWon) {
//     //GameStatus.ENDED
//     return 4;
//   }
//   if (gameCancelled) {
//     //GameStatus.CANCELLED
//     return 6;
//   }
//   if (nowTimestamp < gameTimestamp) {
//     //GameStatus.CREATED
//     return 1;
//   }
//   if (
//     nowTimestamp > gameTimestamp &&
//     nowTimestamp < gameTimestamp + contractAndAuth.expirationDuration
//   ) {
//     if (gameSeed > 0) {
//       //GameStatus.STARTED
//       return 3;
//     }
//     return 2; //GameStatus.READY
//   }
//   return 5; //GameStatus.EXPIRED
// };

// export const fetchGameList = createAsyncThunk(
//   "fetchGameList",
//   async ({ searchParams, contractAndAuth, wallet, gameTypes }) => {
//     try {
//       const start = searchParams.get("start");
//       const end = searchParams.get("end");
//       const ap = searchParams.get("ap");

//       const nowtimestamp = Math.floor(Date.now() / 1000);
//       let gameCounter = Number(await contractAndAuth.contract.gameCounter());
//       let list = [];
//       let jonedGameList = [];

//       // let cardslength = 0;
//       // try {
//       //   //TODO: Kart uzunluğu apiden alınacak (200)
//       //   for (cardslength; cardslength < 200; cardslength++) {
//       //     const cards = await contractAndAuth.contract.cards(cardslength);
//       //   }
//       // } catch (err) {
//       //   console.log("err > (cardslength):", err.code);
//       // }

//       for (gameCounter; gameCounter > 0; gameCounter--) {
//         const currentGameStatus = await gameStatus(
//           gameCounter,
//           contractAndAuth
//         );

//         if (
//           currentGameStatus === 1 ||
//           currentGameStatus === 2 ||
//           currentGameStatus === 3
//         ) {
//           const games = await contractAndAuth.contract.games(gameCounter);
//           console.log(games);

//           let isJoinedGame = 0;
//           try {
//             const tx = await contractAndAuth.contract.playerCards(
//               gameCounter,
//               wallet.accounts[0],
//               0
//             );
//             console.log(tx);
//             isJoinedGame = 1;
//           } catch (error) {
//             isJoinedGame = 0;
//           }

//           const gametimestamp = Number(games[1]);
//           const dateFormatGame = new Date(gametimestamp * 1000);
//           const gamecancelled = games[7]; //cancelled
//           // let gamecardprice = Number(
//           //   (+utils.formatEther(BigNumber.from(games[3]))).toFixed(0)
//           // );
//           const gamecardprice = utils.formatEther(BigNumber.from(games[3])).toString();

//           let maxCardsPerPlayer = Number(games[2]);
//           let totalCardsSold = Number(games[4]); // soldout durumunu kontrol et
//           console.log(dateTime(dateFormatGame));

//           const gameap = dateTime(dateFormatGame).split(" ")[1];
//           let gamehours = Number(
//             dateTime(dateFormatGame).split(" ")[0].split(":")[0]
//           );

//           if (nowtimestamp > gametimestamp && isJoinedGame === 1) {
//             // or gameStatus === 1
//             gameTypes.forEach((gametype) => {
//               if (gamecardprice === gametype.price) {
//                 list.push([
//                   dateTime(dateFormatGame),
//                   gametype.name,
//                   gameCounter,
//                   gamecardprice,
//                   maxCardsPerPlayer,
//                   totalCardsSold,
//                   isJoinedGame,
//                 ]);
//               }
//             });
//           }
//           if (
//             nowtimestamp < gametimestamp &&
//             gamehours >= start &&
//             gamehours <= end &&
//             gameap === ap
//           ) {
//             // or gameStatus === 1
//             gameTypes.forEach((gametype) => {
//               if (gamecardprice === gametype.price) {
//                 list.push([
//                   dateTime(dateFormatGame),
//                   gametype.name,
//                   gameCounter,
//                   gamecardprice,
//                   maxCardsPerPlayer,
//                   totalCardsSold,
//                   isJoinedGame,
//                 ]);
//               }
//             });
//           }
//         }
//       }
//       //TODO (akkoc): room konusu için gene bu kısımıda kullanabilirsin. list dizisi room a ait oyunları verir.
//       list.sort();
//       return [189, list];
//     } catch (error) {
//       console.error(error);
//     }
//   }
// );

export const getFilterTimes = createAsyncThunk("getFilterTimes", async ({ endHour, timeZone }) => {
  const nowtimestamp = Date.parse(Date()) / 1000;
  let filterNowHour = dateTime(new Date((nowtimestamp + 60) * 1000)).split(" ")[0].split(":")[0] % 12;
  let filterNowMin = dateTime(new Date((nowtimestamp + 60) * 1000)).split(" ")[0].split(":")[1];
  console.log(filterNowHour, filterNowMin)
  let filtertimes = ["ALL TIMES"];
  while (true) {
    if (filterNowMin <= 59) {
      if (filterNowHour === 0) {
        filtertimes.push(`12:${filterNowMin.toString().padStart(2, "0")} ${timeZone.toUpperCase()}`);
      } else {
        filtertimes.push(`${filterNowHour.toString().padStart(1, "0")}:${filterNowMin.toString().padStart(2, "0")} ${timeZone.toUpperCase()}`);
      }
      filterNowMin++;
    } else {
      filterNowMin = 0;
      if (filterNowHour < endHour) {
        filterNowHour++;
      } else {
        break;
      }
    }
  }
  return filtertimes;
}
);

export const gameFiltering = createAsyncThunk("gameFiltering", async ({ contractAndAuth, gameList, pot, cardprice, time }) => {
  const formatTime = time.replace(':', '-').replace(' ', '-');
  let filterArry = [];
  let gamePot = null;
  const potmin = Number(pot.split("-")[0]);
  const potmax = Number(pot.split("-")[1]);

  const getGamePot = async (gameId) => {
    const game = await contractAndAuth.contract.games(gameId)
    const gamecardprice = Number(utils.formatEther(BigNumber.from(game.cardPrice)));
    const totalCardsSold = Number(game.totalCardsSold);
    gamePot = totalCardsSold * gamecardprice;
  }

  gameList.forEach(async (gameItems) => {
    if (pot !== "0") {
      getGamePot(gameItems.gameId)
    }

    if (
      gameItems.startedPrice === cardprice &&
      formatTime === "0" &&
      pot === "0"
    ) {
      filterArry.push(gameItems);
    } else if (
      gameItems.startDateAMPM === formatTime &&
      cardprice === "0" &&
      pot === "0"
    ) {
      filterArry.push(gameItems);
    } else if (
      gamePot >= potmin &&
      gamePot <= potmax &&
      cardprice === "0" &&
      formatTime === "0"
    ) {
      filterArry.push(gameItems);
    } else if (
      gameItems.startedPrice === cardprice &&
      gameItems.startDateAMPM === formatTime &&
      pot === "0"
    ) {
      filterArry.push(gameItems);
    } else if (
      gameItems.startedPrice === cardprice &&
      gamePot >= potmin &&
      gamePot <= potmax &&
      formatTime === "0"
    ) {
      filterArry.push(gameItems);
    } else if (
      gameItems.startDateAMPM === formatTime &&
      gamePot >= potmin &&
      gamePot <= potmax &&
      cardprice === "0"
    ) {
      filterArry.push(gameItems);
    } else if (
      gameItems.startedPrice === cardprice &&
      gameItems.startDateAMPM === formatTime &&
      gamePot >= potmin &&
      gamePot <= potmax
    ) {
      filterArry.push(gameItems);
    }
  });
  return filterArry;
}
);

export const joinStore = createSlice({
  name: "joinStore",
  initialState: {},
  reducers: {},
});

export default joinStore.reducer;
