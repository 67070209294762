// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Contract, providers } from "ethers";
import Bingo from "../../../abis/Bingo.sol/Bingo.json";

const gameStatus = async (gameId, contractAndAuth) => {
  const jammyWon = (await contractAndAuth.contract.gamePrizes(gameId, 0))[1];
  const game = await contractAndAuth.contract.games(gameId);
  const nowTimestamp = Math.floor(Date.now() / 1000);
  const gameTimestamp = Number(game.startDate);
  const gameCancelled = game.cancelled;
  const gameSeed = game.seed;

  if (gameTimestamp === 0) {
    //GameStatus.INVALID
    return 0;
  }
  if (jammyWon) {
    //GameStatus.ENDED
    return 4;
  }
  if (gameCancelled) {
    //GameStatus.CANCELLED
    return 6;
  }
  if (nowTimestamp < gameTimestamp) {
    //GameStatus.CREATED
    return 1;
  }
  if (
    nowTimestamp > gameTimestamp &&
    nowTimestamp < gameTimestamp + contractAndAuth.expirationDuration
  ) {
    if (gameSeed > 0) {
      //GameStatus.STARTED
      return 3;
    }
    return 2; //GameStatus.READY
  }
  return 5; //GameStatus.EXPIRED
};

export const getAccount = createAsyncThunk("getAccount", async () => {
  const provider = new providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const bingoContract = new Contract(
    process.env.REACT_APP_CONTRACT_ADDRESS,
    Bingo.abi,
    signer
  );

  const accounts = await window.ethereum.request({
    method: "eth_accounts",
  });

  const admins = await bingoContract.admins(accounts[0]);
  const hosts = await bingoContract.hosts(accounts[0]);

  if (accounts[0] && admins) {
    return {
      account: accounts[0],
      role: "admin",
    };
  } else if (accounts[0] && hosts) {
    return {
      account: accounts[0],
      role: "host",
    };
  } else if (accounts[0]) {
    return {
      account: accounts[0],
      role: "user",
    };
  }
});

export const lobbyStore = createSlice({
  name: "lobbyStore",
  initialState: {},
  reducers: {},
});

export default lobbyStore.reducer;
