// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close-btn {
  position: fixed;
  top: 5%;
  right: 0;
  width: 60px;
  transform: translate(-50%, 0);
  z-index: 999;
}

.btnloading{
  pointer-events: none !important;
  color: rgba(255, 255, 255, 0.5) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/CloseButton/CloseButton.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,OAAO;EACP,QAAQ;EACR,WAAW;EACX,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,+BAA+B;EAC/B,0CAA0C;AAC5C","sourcesContent":[".close-btn {\n  position: fixed;\n  top: 5%;\n  right: 0;\n  width: 60px;\n  transform: translate(-50%, 0);\n  z-index: 999;\n}\n\n.btnloading{\n  pointer-events: none !important;\n  color: rgba(255, 255, 255, 0.5) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
